/**
 * 特定のエリアの記事一覧ページのテンプレート
 */
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"
import PostList from "../components/post-list"
import Pager from "../components/pager"

const PostListPage = ({ data: { strapiPostArea, allStrapiPost } }) => {
  const { name, path } = strapiPostArea.fields
  const { nodes: posts, pageInfo } = allStrapiPost
  const { currentPage } = pageInfo
  const title = `記事一覧（ ${name} ）` + (currentPage > 1 ? `（ ${currentPage} ページ目）` : ``)

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs parents={[]} leaf={title} />
      <h1 className="display-none">{title}</h1>
      <div className="mb-4">
        <PostList heading="" posts={posts} totalCount={pageInfo.totalCount} />
      </div>
      <div className="mb-4">
        <Pager pageInfo={pageInfo} pathPrefix={path} />
      </div>
    </Layout>
  )
}

export default PostListPage

export const query = graphql`
  query PostListQuery($id: String!, $machineName: String!, $skip: Int!, $limit: Int!) {
    strapiPostArea(id: { eq: $id }) {
      fields {
        name
        path
      }
    }
    allStrapiPost(
      filter: {post_areas: {elemMatch: {machine_name: {eq: $machineName}}}},
      limit: $limit,
      skip: $skip,
      sort: {fields: fields___date, order: DESC}
    ) {
      nodes {
        ...PostTeaser
      }
      pageInfo {
        currentPage
        hasNextPage
        pageCount
        itemCount
        totalCount
        hasPreviousPage
      }
    }
  }
`
